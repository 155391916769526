export default [
  {
    header: 'Нөмөр асуудал',
  },
  {
    title: 'Нээлттэй хавтас',
    route: 'todo-board',
    icon: 'TrelloIcon',
  },
  {
    title: 'Архивын хавтас',
    route: 'todo-history-board',
    icon: 'TrelloIcon',
  },
  {
    header: 'Дуудлага',
  },
  {
    title: 'Дуудлага авах',
    route: 'homeInbound',
    icon: 'HomeIcon',
  },
  {
    title: 'Дуудлага хийх',
    route: 'homeOutbound',
    icon: 'HomeIcon',
  },
  {
    title: 'Статистик',
    route: 'statistic',
    icon: 'PieChartIcon',
  },
  {
    title: 'Qpay',
    route: 'qpays-list',
    icon: 'CreditCardIcon',
  }, 
  {
    title: 'И-баримт',
    route: 'ebarimts-list',
    icon: 'FileTextIcon',
  }, 
  {
    header: 'Тохиргоо',
  },
  {
    title: 'Хэрэглэгч',
    route: 'users-list',
    icon: 'UserIcon',
  },
  {
    title: 'Байгууллага',
    route: 'organizations-list',
    icon: 'HomeIcon',
  },
  {
    title: 'Хандах эрх',
    route: 'roles-list',
    icon: 'LockIcon',
  },
  {
    title: 'Цэс',
    route: 'menus-list',
    icon: 'ServerIcon',
  },
  {
    title: 'Ангилал',
    route: 'ref-categories-list',
    icon: 'GridIcon',
  },
  {
    title: 'Бүтээгдэхүүн',
    route: 'ref-products-list',
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'Төлөв',
    route: 'ref-statuses-list',
    icon: 'PocketIcon',
  },
  {
    title: 'Ач холбогдол',
    route: 'ref-priorities-list',
    icon: 'LayersIcon',
  },
  {
    title: 'Таг',
    route: 'ref-tags-list',
    icon: 'TagIcon',
  },
  {
    header: 'Тайлан',
  },
  {
    title: 'Тайлан гаргах',
    route: 'report',
    icon: 'BarChartIcon',
  }
]
